/* eslint-disable prefer-destructuring */
import { cloneElement } from "react";
import Colors from "../../../config/colors";

export const getFieldBottomStyles = ({ disabled, focused, checked, error }) => {
  const styles = {};

  if (disabled) {
    // styles.borderBottomColor = Colors.BlueGrey[4];
    // styles.borderBottomWidth = 1;
    styles.borderColor = Colors.Grey[14];
  } else if (error) {
    // styles.borderBottomColor = Colors.Red[8];
    // styles.borderBottomWidth = 2;
    styles.borderColor = Colors.Red[6];
    styles.backgroundColor = Colors.Red[13];
  } else if (focused || checked) {
    // styles.borderBottomColor = Colors.Blue[13];
    // styles.borderBottomWidth = 2;
    styles.borderColor = Colors.Blue[13];
  } else {
    // styles.borderBottomColor = Colors.BlueGrey[4];
    // styles.borderBottomWidth = 1;
    styles.borderColor = Colors.BlueGrey[4];
  }

  return styles;
};

export const renderFieldAffix = (affixComponent, props) => {
  let returnComponent = null;
  if (affixComponent) {
    if (
      affixComponent.type.displayName === "SAffixAddon" ||
      affixComponent.type.displayName === "SAffixButton"
    ) {
      returnComponent = cloneElement(affixComponent, props);
    } else {
      returnComponent = affixComponent;
    }
  }
  return returnComponent;
};
