import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {StyleSheet, TextInput, View, Platform} from 'react-native';
import Colors from '../../config/colors';
import {getFieldBottomStyles, renderFieldAffix} from '../common/utils/field';
import SText from '../SText';

class STextField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focused: false,
    };
  }

  handleFocus = () => {
    const {onFocus, editable} = this.props;
    // When field is read-only
    if (!editable) {
      return;
    }
    this.setState({
      focused: true,
    });
    if (onFocus) {
      onFocus();
    }
  };

  handleBlur = () => {
    const {onBlur, editable} = this.props;
    // When field is read-only
    if (!editable) {
      return;
    }
    this.setState({
      focused: false,
    });
    if (onBlur) {
      onBlur();
    }
  };

  render() {
    const {
      label,
      help,
      error,
      disabled,
      prefix,
      suffix,
      noHint,
      onFocus,
      onBlur,
      containerStyle,
      rowStyle,
      labelStyle,
      inputStyle,
      hintStyle,
      inputRef,
      isMandatory,
      ...restProps
    } = this.props;
    const {focused} = this.state;

    const containerStyles = {
      opacity: disabled ? 0.48 : 1,
    };

    const rowStyles = {
      ...getFieldBottomStyles({disabled, error, focused}),
    };
    return (
      <View style={[containerStyles, containerStyle]}>
        {label ? (
          <View style={{display:'flex', flexDirection:'row'}}>
            <SText containerStyle={[styles.label, labelStyle]}>{label}</SText>
            {isMandatory ? <SText size={14} color="#ff706e">*</SText>:null}
          </View>
        ) : null}
        <View style={[styles.row, rowStyles, rowStyle]}>
          {renderFieldAffix(prefix, {disabled, type: 'prefix'})}
          <TextInput
            disabled={disabled}
            style={[styles.input, inputStyle]}
            ref={inputRef}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            numberOfLines={1}
            ellipsizeMode='tail'
            {...restProps}
          />
          {renderFieldAffix(suffix, {disabled, type: 'suffix'})}
        </View>
        {!noHint ? (
          <SText
            color={error ? 'error' : 'secondary'}
            containerStyle={[styles.hint, hintStyle]}>
            {error || help}
          </SText>
        ) : null}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    position: 'relative',
  },
  row: {
    minHeight: 48,
    overflow: 'hidden',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 4,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: Colors.BlueGrey[4],
  },
  input: {
    flex: 1,
    ...Platform.select({
      web: {
        padding: 12,
      },
      android: {
        textAlignVertical: 'top',
        paddingHorizontal: 12,
      },
    }),
    borderRadius: 4,
    fontSize: 16,
  },
  label: {
    fontSize: 12,
    marginBottom: 8,
  },
  hint: {
    fontSize: 12,
    minHeight: 20,
    marginTop: 2,
  },
});

STextField.defaultProps = {
  label: '',
  help: '',
  error: '',
  disabled: false,
  editable: true,
  noHint: false,
};

STextField.propTypes = {
  label: PropTypes.string,
  help: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  editable: PropTypes.bool,
  noHint: PropTypes.bool,
};

export default STextField;
